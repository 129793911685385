import { storeToRefs } from 'pinia';
import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router';

import authenticate from '@js/credentials/authenticate';

import googleAnalytics from '@js/dom/analytics';

import useAuthStore from '@/stores/auth';

export const ENDPOINT = window.CREDERE && window.CREDERE._endpoint;

const router = createRouter({
  history: ENDPOINT
    ? createMemoryHistory()
    : createWebHistory(),
  routes: [{
    path: '/',
    name: 'home-index',
    meta: { public: true, title: '404', subtitle: 'Por favor, escolha um endpoint existente' },
    component: () => import('@/views/Home/Error.vue'),
  }, {
    path: '/blank',
    name: 'home-blank',
    meta: { public: true, title: '204', subtitle: 'Nenhum conteúdo a ser exibido' },
    component: () => import('@/views/Home/Error.vue'),
  }, {
    path: '/unauthorized',
    name: 'home-unauthorized',
    meta: { public: true, title: '401', subtitle: 'O token (authorization) informado é inválido' },
    component: () => import('@/views/Home/Error.vue'),
  }, {
    path: '/:matches(.*)*',
    name: 'home-404',
    meta: { public: true, title: '404', subtitle: 'Por favor, escolha um endpoint existente' },
    component: () => import('@/views/Home/Error.vue'),
  }, {
    path: '/preview',
    name: 'preview-index',
    meta: { public: true },
    component: () => import('@/views/Preview/Index.vue'),
    children: [{
      path: 'faq',
      name: 'preview-faq',
      component: () => import('@/views/Preview/FAQ.vue'),
    }, {
      path: ':embed',
      name: 'preview-embed',
      component: () => import('@/views/Preview/Blank.vue'),
    }],
  }, {
    path: '/customers/form',
    name: 'customers-form',
    component: () => import('@/views/Customers/Form.vue'),
  }, {
    path: '/customers/schema',
    name: 'customers-schema',
    meta: { public: true },
    component: () => import('@/views/Customers/Schema.vue'),
  }, {
    path: '/proposals/form',
    name: 'proposals-form',
    component: () => import('@/views/Proposals/Form.vue'),
  }, {
    path: '/proposals/success',
    name: 'proposals-success',
    component: () => import('@/views/Proposals/Success.vue'),
  }, {
    path: '/simulations/read',
    name: 'simulations-read',
    component: () => import('@/views/Simulations/Read.vue'),
  }, {
    path: '/simulations/form',
    name: 'simulations-form',
    component: () => import('@/views/Simulations/Form.vue'),
  }, {
    path: '/vehicles/form',
    name: 'vehicles-form',
    component: () => import('@/views/Vehicles/Form.vue'),
  }, {
    path: '/vehicles/import',
    name: 'vehicles-import',
    component: () => import('@/views/Vehicles/Import.vue'),
  }, {
    path: '/vehicles/banner',
    name: 'vehicles-banner',
    component: () => import('@/views/Vehicles/Banner.vue'),
  }, {
    path: '/vehicles/showroom',
    name: 'vehicles-showroom',
    component: () => import('@/views/Vehicles/Showroom.vue'),
  }, {
    path: '/public_simulator_settings/form',
    name: 'public-simulator-settings-form',
    component: () => import('@/views/Showroom/Form.vue'),
  }, {
    path: '/public_simulator_settings/find',
    name: 'public-simulator-settings-find',
    meta: { public: true },
    component: () => import('@/views/Showroom/Find.vue'),
  }, {
    path: '/reports/score',
    name: 'reports-score',
    component: () => import('@/views/Reports/Score.vue'),
  }, {
    path: '/bank_commission_rules/form',
    name: 'bank-commission-rules-form',
    component: () => import('@/views/BankCommissionRules/Form.vue'),
  }, {
    path: '/bank_ranking/form',
    name: 'bank-ranking-form',
    component: () => import('@/views/BankRanking/Form.vue'),
  }],
});

router.beforeEach(async (to) => {
  if (to.meta.public) {
    return true;
  }

  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);

  if (user.value.id) {
    return true;
  }

  const failure = await authenticate();

  if (failure) {
    return {
      name: 'home-unauthorized',
      query: {
        title: failure.code,
        subtitle: failure.message,
      },
    };
  }

  return true;
});

router.afterEach((to) => {
  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);

  if (user.value.id) {
    googleAnalytics(to, user.value.id);
  }
});

const IMPORT_MODULE_ERRORS = [
  'Failed to fetch dynamically imported module',
  'Importing a module script failed',
];

router.onError((error) => {
  if (!IMPORT_MODULE_ERRORS.some((i) => error.message.includes(i))) {
    /* eslint-disable no-console */
    console.log('ROUTER/ONERROR FAILURE:');
    console.error(error);
    /* eslint-enable no-console */
  }
});

export function showroom() {
  return window.CREDERE.element === '#credere-embed-app'
    && /\/simulador\/loja\//.test(window.CREDERE.referrer);
}

export default router;
