export const PARENT = window.parent;
export const ORIGIN = '*';
export const PREFIX = 'credere';

export const TYPES = [
  'scroll',
  'load',
  'resize',

  'customer:read',
  'customer:create',
  'customer:update',

  'public_simulator_setting:read',
  'public_simulator_setting:create',
  'public_simulator_setting:update',
  'public_simulator_setting:find',

  'lead:create',
  'lead:update',

  'simulation:create',

  'simulation:read',
  'simulation:read:processing',
  'simulation:read:action',

  'proposal:create',
  'proposal_attempt:create',
  'proposal_attempt:update',

  'vehicle:read',
  'vehicle:create',
  'vehicle:update',
  'vehicle:import',
  'vehicle:list',
  'vehicle:showroom:action',

  'vehicle_model:list',

  'report:score:action',

  'bank_commission_rules:read',
  'bank_commission_rules:update',

  'bank_ranking:read',
  'bank_ranking:update',

  'store:read',
].reduce((reduced, type) => {
  reduced[type] = {
    prefixed: `${PREFIX}:${type}`,
    constant: type.replace(/:/g, '_').toUpperCase(),
  };

  return reduced;
}, {});

function message(type, payload = {}) {
  if (!TYPES[type]) {
    // eslint-disable-next-line no-console
    console.error(`MESSAGE TYPE ${type} NOT FOUND`);
  }

  PARENT?.postMessage({
    type: TYPES[type].prefixed,
    payload,
  }, ORIGIN);
}

export default Object.keys(TYPES).reduce((reduced, type) => {
  reduced[TYPES[type].constant] = (payload) => message(type, payload);

  return reduced;
}, {});
