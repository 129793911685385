const ID = 'credere-tokens';

export function sanitize(string) {
  return string.replace(/[<]/g);
}

export function stylize(string) {
  const tag = document.getElementById(ID) || document.createElement('style');

  tag.id = ID;
  tag.textContent = sanitize(string);

  document.body.append(tag);
}
