<script setup>
  import {
    computed,
    onMounted,
    onBeforeUnmount,
    ref,
  } from 'vue';

  import { storeToRefs } from 'pinia';
  import { useRoute, RouterView } from 'vue-router';

  import MESSAGE from '@js/messages';
  import top from '@js/dom/top';

  import useAuthStore from '@/stores/auth';

  window.CREDERE = window.CREDERE || {};

  const route = useRoute();

  const authStore = useAuthStore();
  const { user, environment } = storeToRefs(authStore);
  const staging = computed(() => !!(environment.value && !/app./.test(environment.value)));

  const key = ref(0);

  function reload() {
    key.value += 1;

    top();
  }

  function resize() {
    if (/^\/preview/.test(window.location.pathname)) {
      return;
    }

    MESSAGE.RESIZE({ height: window.document.body.clientHeight + 1 });
  }

  const resizeObserver = new ResizeObserver(() => {
    setTimeout(() => resize(), 0);
  });

  onMounted(() => {
    resizeObserver.observe(document.body);
  });

  onBeforeUnmount(() => {
    resizeObserver.disconnect();
  });

  const classes = computed(() => ({
    'credere-route': true,
    'environment--staging': staging,
  }));
</script>

<template>
  <span
    v-if="staging"
    class="environment-notice"
  />

  <router-view
    :key="key"
    v-if="user.id || route.meta.public"
    :class="classes"
    @reload="reload"
  />
</template>

<style lang="scss">
</style>
