import { storeToRefs } from 'pinia';

import axios from '@js/clients/axios';
import storage from '@js/clients/storage';
import { stylize } from '@js/dom/tokens';
import { TYPES } from '@js/messages';

import useAuthStore from '@/stores/auth';

function url(environment) {
  let valid = false;

  try {
    valid = new URL(environment) && true;
  } catch (err) { /* */ }

  return valid;
}

function question(label, property) {
  let value;
  const existing = storage.getter(`preview:${property}`) || storage.getter(`credere:preview:${property}`, false);

  while (!value) {
    // eslint-disable-next-line no-alert
    value = window.prompt(label, existing || '');
  }

  storage.setter(`preview:${property}`, value);

  return value;
}

async function perform() {
  let reason;

  const authStore = useAuthStore();
  const { environment, authorization, external } = storeToRefs(authStore);

  authStore.prepare();

  function success(response) {
    authStore.overwrite(response.data.user);
  }

  function failure(error = {}) {
    authStore.overwrite();

    reason = error.response || {};

    if (typeof error === 'string') {
      reason = { code: '?', message: error };
    } else if ([401, 403].includes(reason.status)) {
      reason = { code: reason.status, message: 'O token (authorization) informado é inválido' };
    } else {
      reason = { code: 500, message: `Seu código <iframe> está incorreto ${reason.status ? `(${reason.status})` : ''}` };
    }
  }

  if (authorization.value && url(environment.value)) {
    axios.defaults.headers.Authorization = `Bearer ${authorization.value}`;
    axios.defaults.baseURL = environment.value;

    await axios
      .get(external.value ? '/users/external' : '/users/current')
      .then(success)
      .catch(failure);
  } else {
    failure(window.frameElement ? {
      response: {
        status: 401,
      },
    } : {});
  }

  return Promise.resolve(reason);
}

async function message(event) {
  let reason;

  switch (event.data.type) {
    case TYPES.load.prefixed:
      window.CREDERE = event.data.payload || {};

      if (window.CREDERE.tokens) {
        stylize(window.CREDERE.tokens);
      }

      reason = await perform();

      return reason;
    default:
      break;
  }

  return null;
}

window.addEventListener('message', message, false);

export function authLocal() {
  return window.location === window.parent.location;
}

export function authPreview() {
  return /^\/preview/.test(window.location.pathname);
}

export function authInternal() {
  return [
    import.meta.env.VITE_CREDERE_WEB_HOST_DEVELOPMENT,
    import.meta.env.VITE_CREDERE_WEB_HOST_STAGING,
    import.meta.env.VITE_CREDERE_WEB_HOST_PRODUCTION,
  ].includes(window.location.origin);
}

export default async function authenticate() {
  if (authLocal()) {
    if (authInternal() || authPreview() || import.meta.env.DEV) {
      if (!authInternal() && !window.Cypress) {
        window.CREDERE.environment = question('URL da API:\n(Padrão: https://app.meucredere.com.br/api/v1)', 'environment');
        window.CREDERE.authorization = question('Token de autenticação na API do Credere:', 'authorization');
      }

      const result = await message({
        data: {
          type: TYPES.load.prefixed,
          payload: window.CREDERE,
        },
      });

      return result;
    }

    return {
      code: 500,
      message: 'Você não pode acessar esta página diretamente. Utilize nosso "initializer"',
    };
  }

  return null;
}
