import MESSAGE from '@js/messages';

import { ENDPOINT } from '@/router/app';

export default function top() {
  if (!ENDPOINT && window.location === window.parent.location) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  } else {
    MESSAGE.SCROLL();
  }
}
