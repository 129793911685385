const ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;

let INITIALIZED = false;

function initialize() {
  INITIALIZED = true;

  const element = document.createElement('script');
  element.async = true;
  element.src = `https://www.googletagmanager.com/gtag/js?id=${ID}`;

  document.body.append(element);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };

  window.gtag('js', new Date());
}

export default function googleAnalytics(to, userID) {
  if (!ID) {
    return;
  }

  if (!INITIALIZED) {
    initialize();
  }

  if (window.gtag) {
    window.gtag('config', ID, {
      user_id: userID,
    });

    if (window.location !== window.parent?.location) {
      window.gtag('event', 'parent', {
        referrer: window.CREDERE.referrer,
      });
    }
  }
}
