import { createApp } from 'vue';
import { createPinia } from 'pinia';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import sentry from '@js/clients/sentry';

import App from './App.vue';
import router, { ENDPOINT } from './router/app';

import '@js/clients/fontawesome';
import '@css/app.scss';
import '@css/tokens.scss';

const app = createApp(App);

sentry(app, router);

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(createPinia());
app.use(router);

if (ENDPOINT) {
  router.replace(`/${ENDPOINT}`);
}

app.mount('#credere-embed-app');
